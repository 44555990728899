import React from 'react';

const YesNoDropdown = ({ value, onChange, name, className = "" }) => {
  const handleChange = (e) => {
    const boolValue = e.target.value === "true";
    onChange({
      target: {
        name: name,
        value: boolValue,
        type: 'select',
        checked: boolValue
      }
    });
  };

  return (
    <select 
      value={value?.toString() || "false"}
      onChange={handleChange}
      name={name}
      className={`yes-no-dropdown ${className}`}
    >
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  );
};

export default YesNoDropdown; 