import React, { Component } from "react";
import Navbar from "../../containers/navbar";
import ImageLoader from "../../containers/loader";
import SideMenu from "../../containers/sidemenu";
import * as API from "../../services/api";
import * as util from "../../Util";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
class SupplierEvaluationReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredData: [],
      status: "All",
      use_api: "SupplierEvaluationReport/GetSupplierReports?",
      latestSearch: undefined,
      activePage: 1,
      APIPageNumber: 1,
      APIPageSize: 100,
      Inprogress: false,
      searchQuery: ""
    };
  }
  distinctUntilChangeTimeout = null;

  async componentDidMount() {
    this.GetData(true);
  }

  GetData = async () => {
    if (util.isLoggedIn() === false) {
      this.props.history.push("/login");
    }
    util.showLoader();

    try {
      let result = await API.GetAllSupplierReports(
        this.state.APIPageNumber,
        this.state.APIPageSize
      );

      if (result.data != null && result.data != undefined) {
        console.log("result", result);
        if (result.data.data.items.length < this.state.APIPageSize) {
          // Means end of records reached
          this.state.APIPageNumber = null;
        } else {
          this.state.APIPageNumber = this.state.APIPageNumber + 1;
        }

        this.state.filteredData = result.data.data.items;

        this.setState({
          filteredData: this.state.filteredData
        });
      } else {
        throw "err";
      }
    } catch (err) {
      this.setState({ ...this.state, loaded: true });
    } finally {
      util.hideLoader();
    }
  };

  onSearch = async e => {
    if (this.distinctUntilChangeTimeout) {
      clearTimeout(this.distinctUntilChangeTimeout);
    }
    const debounceTime = 400;
    let value = e.target.value;
    let temp = Math.random();
    this.state.latestSearch = temp;
    this.distinctUntilChangeTimeout = setTimeout(async () => {
      util.showLoader();

      if (!value.trim()) {
        this.setState(
          {
            activePage: 1,
            APIPageNumber: 1,
            filteredData: []
          },
          () => {
            this.GetData();
          }
        );
        return;
      }

      try {
        let result = await API.SearchSupplierForm(value);
        // Check if latest search query ?
        if (this.state.latestSearch != temp) {
          return;
        }
        if (result.data != null) {
          this.setState({
            filteredData: result.data.data.items
          });
        } else {
          throw "err";
        }
      } catch (error) {
      } finally {
        util.hideLoader();
        this.setState({
          activePage: 1,
          APIPageNumber: 1
        });
      }
    }, debounceTime);
  };

  handlePageChange = pageNumber => {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });

    if (this.state.APIPageNumber != null && !this.state.Inprogress) {
      if (this.state.users.length - pageNumber * 12 <= 30) {
        this.state.Inprogress = true;
        this.GetData(true);
      }
    }
  };

  ExportExcel = async () => {
    let result = await API.GetData("SupplierEvaluationReport/ExportToExcel");
    if (result.data) {
      util.CreateLinkAndClick(result.data, "supplier-reports");
    }
  };

  onDropDownChange = async (e) => {
    let value = e.target.value;
    
    this.setState({
      status: value,
      activePage: 1,
      APIPageNumber: 1,
      filteredData: []
    }, async () => {
      util.showLoader();
      try {
        let result = await API.GetAllSupplierReports(
          this.state.APIPageNumber,
          this.state.APIPageSize,
          value === "All" ? "" : value
        );

        if (result.data != null && result.data != undefined) {
          this.setState({
            filteredData: result.data.data.items,
            APIPageNumber: result.data.data.items.length < this.state.APIPageSize 
              ? null 
              : this.state.APIPageNumber + 1
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        util.hideLoader();
      }
    });
  };

  render() {
    const { props } = this;
    return (
      <div>
        <SideMenu />
        <div class="dashboard">
          <Navbar
            onSearch={this.onSearch}
            Heading={"Supplier Evaluation Reports"}
          />
          <section id="contents">
            <ImageLoader />
            <section class="statis text-center">
              <div class="container-fluid">
                <div class="row"></div>
                <div class="row user_section">
                  <div class="form-group statusSelect">
                    <span>
                      <i className="fas fa-file-export hand" onClick={this.ExportExcel}></i>
                      EXPORT
                    </span>
                    <select style={{ display: "none" }}
                      className="statusDropwdown"
                      id="viewselect"
                      onChange={this.onDropDownChange}
                    >
                      <option value={"All"} selected>All</option>
                      <option value={"Assigned"}>Assigned</option>
                      <option value={"approved"}>Approved</option>
                      <option value={"pending"}>Pending</option>
                    </select>
                  </div>
                  <div class="col-md-12">
                    {this.state.filteredData.length != 0 ? (
                      <div>
                        {this.state.filteredData
                          .filter((val, idx) => {
                            let low, high;
                            low = this.state.activePage * 12 - 12;
                            high = this.state.activePage * 12;
                            console.log(low, high, "pagi");
                            if (idx >= low && idx < high) return true;
                            else return false;
                          })
                          .map(item => {
                            return (
                              <div
                                class="col-md-4"
                                onClick={() => {
                                  this.props.history.push(
                                    "/supplier-evaluation-reports/" + item.id
                                  );
                                }}
                              >
                                <div class="section_second form-submissions">
                                  <div className="row">
                                    <div class="img-left">
                                      <img src="/images/dock-audit-ico.png" />
                                    </div>
                                    <div class="content-right">
                                      <div class="row">
                                        <div class="col-md-5 text-left">
                                          <span>PO Number</span>
                                          <label>
                                            #{item.purchaseOrderNumber}
                                          </label>
                                        </div>
                                        <div class="col-md-7 text-left">
                                          <span>Product Delivery Date</span>

                                          <label>
                                            {moment(
                                              item.dateProductDelivered
                                            ).format("MMM DD, YYYY")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      "No Supplier Evaluation Reports"
                    )}
                  </div>
                </div>
                {this.state.filteredData.length != 0 ? (
                  <div className="row">
                    <Pagination
                      hideFirstLastPages
                      prevPageText="Prev"
                      nextPageText="Next"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.filteredData.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      className="custom_pagination"
                      innerClass="custom_pagination"
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(SupplierEvaluationReports);
